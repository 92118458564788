<template>
  <div>
    <div class="head">
      <div class="box">
        <img src="../../assets/img/contact/img-bg.png" class="img" />
      </div>
    </div>
    <div class="content">
      <div class="top">
        <div class="title">联系我们</div>
        <div class="line"></div>
        <div class="title1">CONTACT US</div>
      </div>
      <div class="bom">
        <div class="text">
          <div class="item">公司：壹亩地供应链管理有限公司</div>
          <div class="item">电话：400-169-3100</div>
          <div class="item">地址：湖南省长沙市天心区友谊路413号运成大厦16楼</div>
        </div>
        <div class="map">
          <baidu-map
            :center="center"
            :zoom="zoom"
            style="height: 300px"
            @ready="handler"
            @click="getClickInfo"
            :scroll-wheel-zoom="true"
          >
          </baidu-map>
        </div>
      </div>
    </div>
    <floatBar></floatBar>
  </div>
</template>

<script>
import floatBar from "../../components/FloatBar";
export default {
  metaInfo: {
    title: "地道益农",
    meta: [
      {
        name: "Contact",
        content: "联系我们",
      },
    ],
  },
  components: { floatBar },
  props: {},
  data() {
    return {
      center: { lng: 104.082942, lat: 30.520884 },
      zoom: 20,
    };
  },
  watch: {},
  computed: {},
  methods: {
    handler({ BMap, map }) {
      var point = new BMap.Point(112.995226, 28.129554);
      map.centerAndZoom(point, 17);
      var marker = new BMap.Marker(point); // 创建标注
      map.addOverlay(marker); // 将标注添加到地图中
      var circle = new BMap.Circle(point, 6, {
        strokeColor: "Red",
        strokeWeight: 6,
        strokeOpacity: 1,
        Color: "Red",
        fillColor: "#f03",
      });
      map.addOverlay(circle);
    },
    getClickInfo(e) {
      console.log(e.point.lng);
      console.log(e.point.lat);
      this.center.lng = e.point.lng;
      this.center.lat = e.point.lat;
    },
    toTop() {
      let time = setInterval(() => {
        document.documentElement.scrollTop += -50;
        if (document.documentElement.scrollTop === 0) {
          clearInterval(time);
        }
      }, 10);
    },
  },
  created() {
    this.toTop();
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.head {
  width: 100%;
  height: 368px;
  padding-top: 68px;
  .box {
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .img {
      width: 100%;
      height: 300px;
      position: absolute;
      z-index: -1;
    }
    .text {
      font-size: 36px;
      font-weight: bold;
      color: #fff;
    }
  }
}
.content {
  width: 100%;
  padding-top: 70px;
  .top {
    text-align: center;
    .title {
      color: #000028;
      font-size: 24px;
    }
    .line {
      width: 40px;
      height: 1px;
      background: #ef8200;
      opacity: 1;
      margin: 0 auto;
      margin-top: 8px;
      margin-bottom: 7px;
    }
    .title1 {
      font-size: 14px;
      color: #999999;
    }
  }
  .bom {
    width: 1200px;
    margin: 0 auto;
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 90px;
    .text {
      width: 300px;
      border-bottom: 2px solid #f4f4f4;
      .item {
        font-size: 16px;
        font-weight: 400;
        color: #000014;
        line-height: 40px;
      }
      .item:hover {
        color: #ef8200;
      }
    }
    .map {
      width: 800px;
      height: 300px;
      background: pink;
      margin: 0 auto;
    }
  }
}
</style>
