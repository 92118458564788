<template>
  <div>
    <div class="bar">
      <!-- <div
        class="item"
        v-for="(item, index) in list"
        :key="index"
        @mouseleave="mouseLeave"
        @mouseover="mouseOver(item.idnex)"
      >
        <img class="img" :src="item.img" />
        <div class="text">{{ item.text }}</div>
        <img
          v-if="index === curIndex"
          class="code animated bounceIn"
          :src="item.code"
        />
      </div> -->
      <div class="item" v-if="showTop" @click="toTop">
        <img class="img" src="../assets/img/components/floatBar7.png" />
        <div class="text">返回顶部</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      curIndex: null,
      list: [
        {
          img: require("../assets/img/components/floatBar1.png"),
          code: require("../assets/img/components/code1.png"),
          text: "华阳那些事儿",
          idnex: 0,
        },
        {
          img: require("../assets/img/components/floatBar2.png"),
          code: require("../assets/img/components/code2.png"),
          text: "天府职聘网",
          idnex: 1,
        },
        {
          img: require("../assets/img/components/floatBar3.png"),
          code: require("../assets/img/components/code3.png"),
          text: "感知成都",
          idnex: 2,
        },
        {
          img: require("../assets/img/components/floatBar4.png"),
          code: require("../assets/img/components/code4.png"),
          text: "阳鲜生优选",
          idnex: 3,
        },
        {
          img: require("../assets/img/components/floatBar5.png"),
          code: require("../assets/img/components/code5.png"),
          text: "画个derder",
          idnex: 4,
        },
        {
          img: require("../assets/img/components/floatBar6.png"),
          code: require("../assets/img/components/code6.png"),
          text: "天府新楼市",
          idnex: 5,
        },
      ],
      showTop: false,
    };
  },
  watch: {},
  computed: {},
  methods: {
    mouseOver(i) {
      this.curIndex = i;
    },
    mouseLeave() {
      this.curIndex = null;
    },
    toTop() {
      let time = setInterval(() => {
        document.documentElement.scrollTop += -50;
        if (document.documentElement.scrollTop === 0) {
          clearInterval(time);
        }
      }, 10);
    },
    handleScroll() {
      var scrollTop = document.documentElement.scrollTop;
      if (scrollTop > 300) {
        this.showTop = true;
      } else {
        this.showTop = false;
      }
    },
  },
  created() {},
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
};
</script>
<style lang="less" scoped>
.bar {
  position: fixed;
  right: 30px;
  bottom: 220px;
  z-index: 101;
  .item {
    width: 80px;
    height: 80px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);
    background: #fff;
    text-align: center;
    position: relative;
    .img {
      margin-top: 10px;
      margin-bottom: 4px;
      width: 40px;
      height: 40px;
    }
    .text {
      color: #4d4d4d;
      font-size: 12px;
    }
    .code {
      position: absolute;
      top: -10px;
      left: -130px;
    }
  }
  .item:not(:first-child) {
    border-top: 1px solid #ccc;
  }
  .item:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .item:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .item:nth-child(4) {
    .img {
      margin-top: 10px;
      width: 60px;
      height: 30px;
      margin-bottom: 10px;
    }
  }
  .item:hover {
    background: #ccc;
    opacity: 1;
    .text {
      color: #fff;
    }
  }
}
</style>
